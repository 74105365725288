export function getYear() {
  return new Date().getFullYear()
}

//处理数据成树结构 机构
export function convertToFrontByCode(source) {
  const df = (source, stack = 0) => {
    const ret = {
      id: source.organizationCode,
      label: source.organizationName,
      stack,
    }
    if (Array.isArray(source.organizationSonList)) {
      ret.children = source.organizationSonList.map(n => df(n, stack + 1))
    }
    return ret
  }
  return source.map(n => df(n, 0))
}

//处理数据成树结构 用户
export function convertToFrontById(source) {
  const df = (source, stack = 0) => {
    const ret = {
      id: source.organizationId,
      label: source.organizationName,
      stack,
    }
    if (Array.isArray(source.organizationSonList)) {
      ret.children = source.organizationSonList.map(n => df(n, stack + 1))
    }
    return ret
  }
  return source.map(n => df(n, 0))
}

// 时间
export function formatTime(date) {
  date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  return date
}


// 金额格式处理
export function numberFormat(number, decimals, dec_point, thousands_sep) {
  /*
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * dec_point：小数点符号
  * thousands_sep：千分位符号
  * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.ceil(n * k) / k;
      };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
  }

  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}